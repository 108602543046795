import React from "react";

import Button from "../../ui/Button";
import ContentsContainer from "../../ui/ContentsContainer";

const Community = () => {
  return (
    <ContentsContainer label={"Texada Community"}>
      <p>
        The Texada Community is a place for users like you to share, discuss,
        and vote on ideas and feedback on how to improve our software. Feel free
        to share your thoughts in the Ideas section!
      </p>
      <Button
        text={"Go to Texada Community"}
        link={"https://community.texadasoftware.com/"}
        target="_blank"
      />
    </ContentsContainer>
  );
};

export default Community;
