import React from "react";
import Info from "./Info";
import moment from "moment";

const ReleaseNotice = ({ latestRelease }) => {
  const { release_date, header, link } = latestRelease;

  if (!release_date) return false;

  const noticeDuration = 7; // days
  const today = moment();
  const releaseAge = today.diff(release_date, "days");


  // Escape if release date is in the future, or not recent enough
  if (releaseAge < 0 || releaseAge > noticeDuration) return false;

  // Release is recent, show notice
  return (
    <a className="recent-release" href={link} target="_blank">
      <Info />
      <div className="recent-release-text">
        <p className="recent-release-text-date">
          New Release - {moment(release_date).format("MMMM D")}
        </p>
        <p className="recent-release-text-heading">{header}</p>
      </div>
      <span className="recent-release-more">Learn More</span>
    </a>
  );
};

export default ReleaseNotice;
