import React from "react";
import Button from "../../ui/Button";
import ContentsContainer from "../../ui/ContentsContainer";
import moment from "moment";

const LatestRelease = ({ latestRelease }) => {
  const { release_date, header, content, link } = latestRelease;

  if (!release_date) return false;

  return (
    <ContentsContainer label={"Latest Release"}>
      <h1 className="no-margin">
        {!release_date ? "" : moment(release_date).format("MMMM D, Y")}
      </h1>
      <h2>{header}</h2>
      <p>{content}</p>
      <Button text={"More Info"} link={link} target="_blank" />
      <Button
        text={"View All Release Notes"}
        link={"https://help.texadasoftware.com/knowledge/release-notes"}
        target="_blank"
      />
    </ContentsContainer>
  );
};

export default LatestRelease;
