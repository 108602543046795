import React from "react";

const Button = ({ text, link, target }) => {
  const linkTarget = target === "_blank" ? "_blank" : "_self";

  return (
    <a className="button" href={link} target={linkTarget}>
      {text}
    </a>
  );
};

export default Button;
