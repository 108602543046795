import React from "react";
import { PAGE_NOT_FOUND, IE11_NOT_SUPPORT } from "../../AppConstants";

const ErrorPage = ({ location }) => {
  const { state } = location;
  const message = !state?.message ? PAGE_NOT_FOUND : state.message;
  return (
    <div className="error-container">
      <h1 className="error-container__header">{message}</h1>
      {message === PAGE_NOT_FOUND && (
        <p>Please verify that you have entered a valid URL.</p>
      )}
      {message === IE11_NOT_SUPPORT ? (
        <>
          <p>
            <b>
              Support for Internet Explorer was ended by Microsoft in 2016. We
              recommend using a safer, more modern Internet browser like Google
              Chrome, Mozilla Firefox, or Microsoft Edge.
            </b>
          </p>
          <p>
            For more information on Microsoft&apos;s End of Life Policy towards
            Internet Explorer, please review:{" "}
            <a
              href="https://www.microsoft.com/en-ca/microsoft-365/windows/end-of-ie-support"
              target="_blank"
            >
              https://www.microsoft.com/en-ca/microsoft-365/windows/end-of-ie-support
            </a>
          </p>
        </>
      ) : (
        <p>
          If the problem persists, please{" "}
          <a href="https://texadasoftware.com/support/" target="_blank">
            contact our support team
          </a>
        </p>
      )}
    </div>
  );
};

export default ErrorPage;
