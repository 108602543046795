import React from "react";

import SrmInstanceBox from "./srm_instance_box/SrmInstanceBox";
import ProductRenderControl from "./product_box/ProductRenderControl";

const LeftPanel = ({ company, products: productDescriptions, sessionId }) => {
  const { products, srmInstances } = company;
  return (
    <div className="main-left">
      <SrmInstanceBox
        srmInstances={srmInstances}
        sessionId={sessionId}
      />
      <ProductRenderControl products={products} productDescriptions={productDescriptions}/>
    </div>
  );
};

export default LeftPanel;
