import React from "react";
import TexadaLogo from "../../../public/texada-logo.png";

const Nav = () => {
  return (
    <nav className="header-bottom-wrap">
      <div className="centered">
        <div className="header-bottom">
          <img className="header-bottom-logo" src={TexadaLogo} />
          <input type="checkbox" id="nav-toggle" className="nav-toggle" />
          <nav className="header-bottom-nav">
            <a href="https://texadasoftware.com/support/" target="_blank">
              Customer Support
            </a>
            <a href="https://login.texadasoftware.com/" target="_blank">
              Customer Access
            </a>
            <a href="https://help.texadasoftware.com/knowledge" target="_blank">
              Help Center
            </a>
            <a href="https://texadasoftware.com/" target="_blank">
              Main Website
            </a>
          </nav>
          <label htmlFor="nav-toggle" className="hamburger">
            <span />
          </label>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
