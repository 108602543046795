import React from "react";
import Company from "./Company";

const SrmInstanceBox = ({ srmInstances, sessionId }) => {
  return (
    <div className="box-product">
      <h2 className="section-heading">My SRM Instance</h2>
      <Company srmInstances={srmInstances} />
      <p className="session-id">	
        Session identifier: <b>{sessionId}</b>	
      </p>
    </div>
  );
};

export default SrmInstanceBox;
