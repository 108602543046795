import React from "react";

import ContentsContainer from "../../ui/ContentsContainer";

const LatestNews = () => {
  return (
    <ContentsContainer label={"Latest News"}>
      <iframe
        src="https://feed.mikle.com/widget/v2/128370/?preloader-text=Loading"
        frameBorder="no"
        className="fw-iframe"
        width="100%"
        scrolling="no"
        sandbox="allow-same-origin allow-scripts allow-popups"
      ></iframe>
    </ContentsContainer>
  );
};

export default LatestNews;
