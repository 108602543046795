import React from "react";

const LoginButton = ({ url, text }) => {
  return (
    <a
      className={"login-btn " + (text === "Live" ? "live" : "play")}
      href={url}
      target="_blank"
    >
      {text} Login
    </a>
  );
};

export default LoginButton;
