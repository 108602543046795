import React from "react";

import LoginButton from "../../ui/LoginButton";

const Product = ({ live, play, image }) => {
  return (
    <section className="box-product">
      <img src={image} className="box-product-img" />
      <div className="box-product-buttons">
        {live.length !== 0 ? <LoginButton url={live} text={"Live"} /> : ""}
        {play.length !== 0 ? <LoginButton url={play} text={"Play"} /> : ""}
      </div>
    </section>
  );
};

export default Product;
