import React, { useState, useEffect } from "react";
import axios from "axios";
import Nav from "../nav/Nav";
import { INFO_URL } from "../../AppConstants";

import phone from "../../../public/icons/phone.png";
import email from "../../../public/icons/email.png";
import facebook from "../../../public/icons/facebook.png";
import twitter from "../../../public/icons/twitter.png";

const Container = ({ children }) => {
    
    const [information, setInformation] = useState();

    const callInfo = () => {
        return axios.get(INFO_URL)
    }

    useEffect(() => {

    callInfo()
        .then(res => {
            setInformation(res.data)
        })
        .catch(err => console.log(err))
    }, [])

    const mapPhoneNumbers = (phoneNumbers) => {
        return phoneNumbers.split(",").map(number => {
            return (
                <a href={`tel:${number}`} key={number}>
                    <span className="nav-text">
                        <b>{number}</b>
                    </span>
                </a>
            )
        })
    }

    return (
        <>
            <header className="header-top-wrap">
                <div className="centered">
                    <nav className="header-top">
                        {information && (
                            <>
                                <img className="header-top-phone" src={phone} />
                                {mapPhoneNumbers(information.phone_numbers)}
                                <a href={`mailto:${information.sales_email}`}>
                                    <img className="nav-icon" src={email} />
                                    <span className="nav-text">{information.sales_email}</span>
                                </a>
                                <a href={`${information.facebook}`} target="_blank">
                                    <img className="nav-icon" src={facebook} />
                                </a>
                                <a href={`${information.twitter}`} target="_blank">
                                    <img className="nav-icon" src={twitter} />
                                </a>
                            </>)}
                    </nav>
                </div>
            </header>
            <Nav />
            {children}
        </>
    )
}
export default Container;