import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Main from "../src/components/Main";
import Container from "./components/container/Container";
import ErrorPage from "../src/components/error/ErrorPage";

const App = () => {
  return (
    <BrowserRouter>
      <Container>
        <Switch>
          <Route path="/" component={ErrorPage} exact />
          <Route path="/:id" component={Main} />
        </Switch>
      </Container>
    </BrowserRouter>
  );
};

export default App;
