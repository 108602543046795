import React from "react";
import Button from "../../ui/Button";

const ProductDescription = ({ name, description, page, image }) => {
  const descriptionWithNewline = (description) => {
    return description.split("\n").map((desc, index) => {
      if (index + 1 === description.split("\n").length) {
        return desc
      }
      return (
        <>
          {desc}
          <br />
        </>
      )
    })
  }
  return (
    <section className="box-promo">
      <img className="box-promo-img" src={image} />

      <p>{descriptionWithNewline(description)}</p>
      <Button text={"Learn More"} link={page} target="_blank" />
    </section>
  );
};

export default ProductDescription;
