import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import TagManager from "react-gtm-module";
import Cookies from "universal-cookie";
import * as HttpStatus from "http-status-codes";
import DOMPurify from "dompurify";
require("es6-promise").polyfill();

import LeftPanel from "./leftPanel/LeftPanel";
import RightPanel from "./rightPanel/RightPanel";

import {
  COMPANY_URL,
  LATEST_RELEASE_URL,
  MARKETING_URL,
  PRODUCT_URL,
  PAGE_NOT_FOUND,
  SERVER_ERROR,
  IE11_NOT_SUPPORT
} from "../AppConstants";

import "../index.less";

const tagManagerArgs = {
  gtmId: "GTM-KFVSB39"
};

const Main = () => {
  const [company, setCompany] = useState();
  const [latestRelease, setLatestRelease] = useState();
  const [marketing, setMarketing] = useState();
  const [products, setProducts] = useState();
  const [sessionId, setSessionId] = useState();
  const { id } = useParams();
  const history = useHistory();
  const cookies = new Cookies();

  const callProductInfo = () => {
    return axios.get(`${COMPANY_URL}/${id}/`, {
      headers: {
        "Cache-Control": "no-cache",
        "Pragma": "no-cache",
        "Expires": "0"
      }
    });
  };

  const callLatestRelease = () => {
    return axios.get(LATEST_RELEASE_URL);
  };

  const callMarketing = () => {
    return axios.get(MARKETING_URL);
  };

  const callProduct = () => {
    return axios.get(PRODUCT_URL);
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    if (!window.ActiveXObject && "ActiveXObject" in window) {
      history.push("/", { message: IE11_NOT_SUPPORT });
    } else {
      callProductInfo()
        .then(res => {
          setCompany(res.data);
          Promise.all([callLatestRelease(), callMarketing(), callProduct()])
            .then(([latestRelease_data, marketing_data, products_data]) => {
              setLatestRelease(latestRelease_data.data);
              setMarketing(marketing_data.data);
              setProducts(products_data.data);
            })
            .catch(e => {
              history.push("/", { message: SERVER_ERROR });
            });
          setInterval(() => {
            callProductInfo().then(res => setCompany(res.data));
          }, 30000);
        })
        .catch(err => {
          let errorMsg;
          if (err.response.status === HttpStatus.NOT_FOUND) {
            errorMsg = PAGE_NOT_FOUND;
          } else {
            errorMsg = SERVER_ERROR;
          }
          history.push("/", { message: errorMsg });
        });
      return () => clearInterval();
    }
  }, []);

  useEffect(() => {
    let myVar = cookies.get("TexadaBrowserId");
    if (!myVar) {
      myVar = make_random();
    }
    cookies.remove("TexadaBrowserId", {
      path: "/",
      domain: "/"
    });
    setSessionId(myVar);
    let today = new Date();
    today.setTime(today.getTime());
    let expires = 30 * 1000 * 60 * 60 * 24;
    var expires_date = new Date(today.getTime() + expires);
    cookies.set("TexadaBrowserId", myVar, {
      path: "/",
      expires: expires_date,
      domain: ".texadasoftware.com",
      secure: true,
      sameSite: "strict"
    });
  }, []);

  const make_random = () => {
    let array = new Uint8Array(1);
    let randvar = "xxyy-yyxx".replace(/[xy]/g, function(c) {
      window.crypto.getRandomValues(array);
      let r = array[0] % 16,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16).toUpperCase();
    });
    return randvar;
  };

  const displayAlert = () => {
    if (!company) return;
    if (
      company?.server?.alert_header.length !== 0 ||
      company?.server?.alert_content.length !== 0
    ) {
      const sanitizedContent = DOMPurify.sanitize(
        company?.server?.alert_content
      );

      return (
        <div className="alert-wrap">
          <div className="centered">
            <h1 className="alert-header">{company?.server?.alert_header}</h1>
            <div
              className="alert-content"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {displayAlert()}
      <main className="main-wrap">
        <div className="centered">
          <div className="main">
            {company && latestRelease && marketing && products && (
              <>
                <LeftPanel
                  company={company}
                  products={products}
                  sessionId={sessionId}
                />
                <RightPanel
                  latestRelease={latestRelease}
                  marketing={marketing}
                />
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Main;
