import React from "react";

import Button from "../../ui/Button";
import { convertImgUrl } from "../../helpers/helpers";

const idIndex = 7;

const MarketingContainer = ({ marketing }) => {
  if (!marketing.subject) return false;

  const extractYoutubeId = (link) =>
    link.match(
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    )[idIndex];

  const isYoutubeUrl = (link) =>
    link.match(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/);

  return (
    <>
      <div className="box-content">
        {isYoutubeUrl(marketing.link) ? (
          <iframe
            className="marketing-video"
            src={`https://www.youtube.com/embed/${extractYoutubeId(
              marketing.link
            )}?autoplay=1&mute=1&loop=1&playlist=${extractYoutubeId(
              marketing.link
            )}`}
            frameborder="0"
            allow="autoplay"
            allowfullscreen
          ></iframe>
        ) : (
          <a href={marketing.link} target="_blank">
            <img
              className="marketing-img"
              src={convertImgUrl(marketing.image)}
            />
          </a>
        )}
        <h1>{marketing.subject}</h1>
        <h3>{marketing.content}</h3>
        <Button
          text={marketing.button_text || "Learn More"}
          link={marketing.button_link || marketing.link}
          target="_blank"
        />
      </div>
    </>
  );
};

export default MarketingContainer;
