import React from "react";

import ReleaseNotice from "./releaseNotice_box/ReleaseNotice";
import MarketingContainer from "./marketing_box/MarketingContainer";
import LatestRelease from "./latestRelase_box/LatestRelease";
import LatestNews from "./latestNews_box/LatestNews";
import Community from "./community_box/Community";

const RightPanel = ({ news, latestRelease, marketing }) => {
  return (
    <div className="main-right">
      <ReleaseNotice latestRelease={latestRelease} />
      <MarketingContainer marketing={marketing} />
      <Community />
      <LatestRelease latestRelease={latestRelease} />
      <LatestNews news={news} />
    </div>
  );
};

export default RightPanel;
