import React, { useEffect, useState } from "react";
import Product from "./Product";
import ProductDescription from "./ProductDescription";

const ProductRenderControl = ({ products, productDescriptions }) => {

  const [companyProducts, setCompanyProducts] = useState([])
  const [productDesc, setProductDesc] = useState([]);

  useEffect(() => {
    if(!(window.ActiveXObject) && "ActiveXObject" in window) return;
    const mappedProducts = products.map(product => {
      const targetImg = productDescriptions
        .filter(item => item.name === product.product)
        .map(({ image }) => image)
      return {
        ...product,
        image: targetImg[0]
      }
    })
      const mappedDescription = productDescriptions.map(desc => {
        if (products.find(item => item.product === desc.name)) {
          return false
        }
        return desc;
      }).filter(item => item)
      setCompanyProducts(mappedProducts)
      setProductDesc(mappedDescription)
  }, [])

  return (
    <>
      {
        companyProducts.map(product => {
          return (
            <Product
              key={product.product.name}
              live={product.live}
              play={product.play}
              image={product.image}
            />
          )
        })
      }
      {productDesc.map(({ name, description, page, image }) => {
        return (
          <ProductDescription
            key={name}
            description={description}
            page={page}
            image={image}
          />
        )
      })
      }
    </>
  );
};

export default ProductRenderControl;
