import React from "react";

const ContentContainer = ({ label, children }) => {
  return (
    <div className="box-content">
      <div className="section-heading">{label}</div>
      {children}
    </div>
  );
};

export default ContentContainer;
