const BASE_URL = window.location.origin;
export const COMPANY_URL = `${BASE_URL}/rest-api/companies`;
export const LATEST_RELEASE_URL = `${BASE_URL}/rest-api/versions/latest_release/`;
export const MARKETING_URL = `${BASE_URL}/rest-api/articles/latest_article/`;
export const PRODUCT_URL = `${BASE_URL}/rest-api/products/`;
export const INFO_URL = `${BASE_URL}/rest-api/texada/latest`;

export const PAGE_NOT_FOUND = "Page Not Found";
export const SERVER_ERROR = "Server Error";
export const IE11_NOT_SUPPORT = "Internet Explorer 11 is not supported.";
