import React from "react";
import Uri from "jsuri";
import Cookies from "universal-cookie";

import LoginButton from "../../ui/LoginButton";

const Company = ({ srmInstances }) => {

  const cookies = new Cookies();

  const generateFinalUrl = (session_name, company_code, server_name, env) => {
    if (server_name === 'localhost' || server_name === '127.0.0.1') {
      server_name = window.location.hostname;
    }

    const xfer_in = `___ ___ ${company_code} TEXID-${cookies.get("TexadaBrowserId")}`;
    if (server_name.includes("naoc7") || server_name.includes("naocpre") || server_name.includes("naoc6") || server_name.includes("naoc4") || server_name.includes("naoc3")) {
      const session = session_name.split("_")[0];
      const finalUrl = new Uri("https://" + server_name + ":10814/" + session + "-" + env + "/connect")
        .addQueryParam("SESSION_NAME", "Default")
        .addQueryParam("XFER_IN", xfer_in)
        .toString()
      return finalUrl.replace(/%20/g, "+")
    }

    const finalUrl = new Uri("https://" + server_name + ":8815/OpenClient/connect")
      .addQueryParam('SESSION_NAME', session_name)
      .addQueryParam('XFER_IN',xfer_in)
      .addQueryParam('COMP',company_code)
      .addQueryParam('XFER_IN_SEL')
      .toString()

    return finalUrl.replace(/%20/g, "+")
  }

  return srmInstances.map((
    { name,
      live_session_name,
      company_code,
      live,
      play_session_name,
      play
    }) => (
      <div className="box-product-company" key={name}>
        <h1>{name}</h1>
        <div className="box-product-buttons">
          {live.length !== 0 ? <LoginButton url={generateFinalUrl(live_session_name, company_code, live, "live")} text={"Live"} /> : ""}
          {play.length !== 0 ? <LoginButton url={generateFinalUrl(play_session_name, company_code, play, "play")} text={"Play"} /> : ""}
        </div>
      </div>
    ));
};

export default Company;
